.full-width {
  margin-bottom: 1rem;
}
@media screen and (max-width: 480px) {
  .full-width {
    width: 100%;
  }
}
.aside {
  position: fixed;
  right: -100vw;
  left: 100vw;
  bottom: 0;
  top: 64px;
  overflow: auto;
  z-index: 500;
  transition: all 0.2s ease-in-out;
  padding: 1rem;
  background: #ededed;

  &.open {
    right: 0;
    left: 0;

    @media #{$medium-up} {
      left: $mobile-menu-width;
    }

    @media (max-width: 1024px) {
      left: 0;
    }
  }
}

.select-wrapper {
  width: 100%;
}

#modal-car, #modal-filling {
  height: auto;
  max-height: 80vh;
  z-index: 500;
}

.btn {
  background: $nlf-blue-light;

  &:hover {
    background: lighten($nlf-blue-light, 5);
  }
}

#newFilling {
  float: right;
  margin-right: 1rem;
}

.white-table {
  background: #fff;
  margin-bottom: 1rem;
}

.select-group {
  width: 80%;

  h3 {
    color: #000;
    margin-top: 3rem;
  }
}

#global-toast {
  position: absolute;
  display: none;
  bottom: 0;  left: 0; right: 0;
  padding: 0.5rem 1rem;
  line-height: 1;
  background: $success;
  color: #fff;
  align-items: center;
  height: 40px;
  i {
    margin-right: 0.5rem;
  }

  .timetype {
    text-transform: capitalize;
  }

  span#time {
    display: flex;
    flex: 1;
    align-items: center;
  }

  &.active {
    display: flex;
  }

  @media #{$large-up} {
    left: $mobile-menu-width;
  }
}

#content {
  position: relative;

  &.front {
    height: 100%;
  }

  &.timer-active {
    .aside {
      bottom: 40px;
    }
  }
}

.emergency-number {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;

  a {
    width: 20%;
    text-align: center;
    line-height: 140%;
    padding: 1rem;
    border-radius: 3px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 900;

    &#politi {
      background: #3366FF;
      color: darken(#3366FF, 40);
    }

    &#brannbil {
      background: #FF3333;
      color: darken(#FF3333, 40);
    }

    &#sykebil {
      background: #00BB33;
      color: darken(#00BB33, 40);
    }

    &#kollegahjelpen {
      background: #FFDD00;
      color: darken(#FFDD00, 40);
    }

    .phone-number {
      color: rgba(0, 0, 0, .5);
      display: block;
      font-size: 17px;
      border-top: 1px solid rgba(255, 255, 255, .1);
      margin-top: 5px;
      padding-top: 4px;
      letter-spacing: 1px;
    }
  }

  @media (max-width: 767px) {
    a {
      margin-top: 15px;
      width: 45%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
}