@charset 'utf-8';

@import '../foundation-sites/scss/foundation';
@import 'inc/settings';
@import 'inc/mixins';
@import 'inc/components';
@import 'inc/text';
@import 'inc/article-cards';
@import 'inc/content-section';
@import 'inc/card';
@import 'inc/timesheet-colors';
@import 'inc/timesheets';
@import 'inc/contact-list';
@import 'inc/article';
@import 'inc/ath';
@import 'inc/pagination';
@import 'inc/dropdown';
@import 'inc/user';
@import 'inc/deviation';
@import 'inc/button';
@import 'inc/icon-selector';
@import 'inc/color-selector';
@import 'inc/tanking';
@import 'inc/documents';
@import 'inc/handbook';
@import 'inc/handbook_main';
@import 'inc/nlf_card';

@include foundation-flex-grid;
//@include foundation-global-styles;

@import url(https://fonts.googleapis.com/css?family=Roboto:400,300);

* {
  box-sizing: border-box;
  -webkit-overflow-scrolling: touch;
}

body,
html {
  height: 100%;
  margin: 0;
}

.hidden {
	display: none;
}

.left {
  float: left;
}

.right {
  float: right;
}

body {
  //padding-top: $nav-height;
  font-family: 'Roboto', sans-serif;
  line-height: 1;
  background: $nlf-background;
  overflow-x: hidden;

  &.open {
    overflow: hidden;
  }

  &.login {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media #{$large-up} {
    padding-left: $mobile-menu-width;
  }
} /* END body */

#content {
  padding-top: $nav-height;
}

#nav-header {
  position: fixed; top: 0; left: 0; right: 0;
  width: auto;
  display: flex;
  align-items: center;
  height: $nav-height;
  background: $nlf-grey-dark;
  z-index: 1000;
  transition: all 0.2s ease-in-out;

  @media #{$large-up} {
    left: $mobile-menu-width;
  }

  #menu {
    font-size: 2rem;
    color: #fff;
    margin-left: 1rem;

    &:hover {
      cursor: pointer;
    }

    @media #{$large-up} {
      display: none;
    }
  }

  #more {
    display: inline-block;
    background: none;
    border: none;
    margin-left: auto;
    margin-right: 10px;

    &:hover {
      cursor: pointer;
    }

    i {
      position: relative;
      top: 4px;
      padding-right: 10px;
      padding-left: 20px;
      display: inline-block;
      font-size: 2rem;
      color: #fff;
    }
  }

  .nav-title {
    color: #fff;
    font-size: 1.2rem;
    margin-left: 1rem;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

nav {
  height: 100%;
}

.mm-page {
  height: 100%;
}

.mm-listview {
  .mobile-menu-li {
  }
  li {
    width: 100%;

    .nav-link-title {
      .material-icons {
        display: inline-block;
        line-height: inherit;
        height: inherit;
        vertical-align: middle;
      }
    }    
  }
}
/* END nav */

#user {
  //height: 200px;
  padding-bottom: 10px;
  background: rgba(0,0,0,0.15);
  display: flex;
  flex-wrap: wrap;

  .user-name {
    flex: 100%;
    color: #fff;
    text-align: center;
  }

  button {
    color: #fff;
    text-decoration: none;
  }

  button, span {
    display: flex;
    flex: 1 1 100%;
    justify-content: center;
    align-items: center;
    font-weight: normal;
  }

  img {
    border-radius: 100%;
    width: 115px;
    height: 115px;
    -webkit-filter: grayscale(30%);
    object-fit: cover;
  }

  .edit-button {
    flex: none;
    display: block;
    line-height: 1;
    margin: 0 auto;
    padding: 10px 60px;
  }
} /* END #mobile-nav */

.page-dim {
  position: fixed;
  top: $nav-height;
  bottom: 0;
  left: 0;
  opacity: 0;
  background: #000;
  z-index: 800;
  transition: opacity 0.2s ease-in-out;

  &.open {
    right: 0;
    opacity: 0.5;

    @media #{$medium-up} {
      display: none;
    }
  }
} /* END .page-dim */

.app-module-line {
  background: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  cursor: pointer;

  a {
    display: block;
    color: #fff;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 1.1rem;

    &:after {
      content: "";
      position: absolute; top: 0; left: 0;
      width: 100%;
      height: 100%;
    }
  }

	svg {
		height: 11vw;

		path, polygon {
			fill: rgba(0,0,0, 0.3);
		}
	}
} /* END .app-module-line */

.flex-container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: strech;
  flex-wrap: wrap;

  .item {
    flex-direction: column;
    flex: 1 1 50%;

    @media #{$large-up} {
      flex: 1 1 33.3%;
    }

    span {
      flex: 1 1 0%;
      width: 100%;
      align-content: center;
      justify-content: center;
      display: flex;
      flex-direction: column;

      i {
        display: flex;
        justify-content: center;
        align-content: center;
        width: 100%;
        font-size: 11vw;
        color: rgba(0,0,0,0.4);
      }

      img {
        width: auto;
        height: 11vw;
      }
    }

    button {
      border: none;
      width: 100%;
      justify-content: center;
      background: rgba(0,0,0,0.1);
      padding: 0.9rem 0;
    }
  }

  .item:nth-child(1) {
    @include appModule(#fff, green);
  }

  .item:nth-child(2) {
    @include appModule(#fff, #673AB7);
  }

  .item:nth-child(3) {
    @include appModule(#fff, #009688);
  }

  .item:nth-child(4) {
    @include appModule(#fff, #FF5722);
  }

  .item:nth-child(5) {
    @include appModule(#fff, #4CAF50);
  }

  .item:nth-child(6) {
    @include appModule(#fff, #9C27B0);
  }

  .item:nth-child(7) {
    @include appModule(#fff, #607D8B);
  }

  .item:nth-child(8) {
    @include appModule(#fff, #673AB7);
  }
} /* END .flex-container */

#login-page {
  width: 280px;
  max-width: 90%;
}

.alert {
  border-radius: 2px;
  width: 100%;
  padding: 1rem;
  box-shadow: 0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.2),0 1px 5px 0 rgba(0,0,0,.12);
  background: #666666;
  display: inline-block;
  color: #fff;
  margin-bottom: 1rem;
  text-align: center;

  &.success {
    background: $success;
  }

  &.danger {
    background: $danger;
  }

  &.warning {
    background: $warning;
  }
} /* END .alert */

/*
* Modal
*/
/*
.modal {
	position: absolute;
	top: 64px;
	right: -100%;
	width: 100vw;
	height: calc(100vh - 64px);
	background: $nlf-background;
	//background: rgba(255,0,0, 0.2);
	transition: all 0.2s ease-in-out;

	&.centered {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&.active {
		right: 0;
	}
}
*/
.user-image32 {
  margin-top: 1rem;

  img {
    border-radius: 100%;
    width: 200px;
    height: 200px;
    -webkit-filter: grayscale(30%);
    object-fit: cover;
  }
}

.user-edit {
  text-align: center;

  .buttonblock {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .user-image {
    max-width: 100%;
  }
}

.fylling-wrapper {
  padding: 1rem;
}

.numCol {
	text-align: right;
}

.nlf-card {
  display: flex;
  height: 100%;
  background: #fff;

  justify-content: center;
  align-items: center;

  img {
    max-height: 80%;
    width: auto;
  }
}

.toast {
  word-break: break-word;
}

@media all and (display-mode: fullscreen) {
  body {
    * {
      background: red !important;
    }
  }
}

button[data-href] {
  border: none;
}
.materializeCssModal {
  max-height: 98%;
  top: 1% !important;
  width: 100%;
  word-break: break-word;

  .modal-title {
    font-size: 2rem;
  }
  .modal-input-wrapper {
    max-width: 90%;
  }

  .modal-footer {
    .btn {
      margin: 6px 10px;
      float: none;
      clear: both;
    }
  }
}