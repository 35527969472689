.ath {
    display: none;
    position: absolute;
    bottom: 0;
    z-index: 9;
    width: 100%;

    .card {
        margin: 0;

        .card-title {
            background: none;
        }
    }
}
