// Timesheet colors
$activity-green: #23A455;
$activity-blue: #2D8DD6;
$activity-purple: #904FAD;
$activity-red: #E44235;
$activity-grey: grey;

[data-activity-color="green"] {
    background: $activity-green;

    &.active {
      box-shadow: 0 0 10px 5px lighten($activity-green, 30);
    }
}

[data-activity-color="blue"] {
    background: $activity-blue;

    &.active {
      box-shadow: 0 0 10px 5px lighten($activity-blue, 30);
    }
}

[data-activity-color="purple"] {
    background: $activity-purple;

    &.active {
      box-shadow: 0 0 10px 5px lighten($activity-purple, 30);
    }
}

[data-activity-color="red"] {
    background: $activity-red;
}

[data-activity-color="grey"] {
    background: $activity-grey;
}