.content-section {
    background: #EDEDED;
    box-shadow: inset 0 2px 5px 0 rgba(0,0,0,0.16);
    overflow: hidden;
    padding: 1rem;

    &__header {
        &__title {
            font-weight: 300;
            margin: 1.5rem 0 1rem;
        }
    }

    &__content {
        padding: 0;
    }

    @media (max-width: 767px) {
        text-align: center;

        &__header {
            &__title {
                font-size: 7vw;
            }
        }

        .card {
            margin: 0.5rem 0;
            width: 100%;
        }
    }
}
