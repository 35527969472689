#articles,
.articles {
  padding: 1rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: flex-start;

  .card {
    margin-right: 1rem;

    @media #{$large-up} {
      max-width: 50%;
    }

    a {
      color: #000;
    }

    .card-title {
      display: block;
      background: none;
      padding: 20px;
    }

    .card-image {
      .card-title {
        background: rgba(0, 0, 0, .7);
      }
    }
  }
}
