.link-button {
    background: none;
    box-shadow: none;
    color: $link-color;
    padding: 0;

    &:hover {
        background: none;
        box-shadow: none;
    }
}

.btn {
	font-size: 8pt;
	margin: 3px;
}