.article {
  font-weight: 300;
  padding: 1rem;

  &:after {
    content: '';
    clear: both;
    display: block;
  }

  ul, ol {
      list-style-position: inside;
      list-style-type: circle;
  }
  li {
      list-style: inherit;
  }

  p {
    font-size: 1.2rem;
    line-height: 140%;
  }

  img {
    height: auto;
    max-width: 100%;
  }

  figure {
    margin: 0;
  }

  .intro {
    font-weight: bold;
  }
}
