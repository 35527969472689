.handbookWrapper {
	padding-right: 15px;
	padding-left: 15px;

	.nav-link-title {
		display: inline-block;
		padding-top: 15px;

		i {
			vertical-align: middle;
		}

		span {
			vertical-align: middle;
		}
	}
}