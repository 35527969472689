
body {
  font-size: 14px;
}

h1 {
  font-size: 24px;
  font-weight: normal;
}

h2 {
  font-size: 20px;
  font-weight: bold;
}

h3 {
  font-size: 16px;
  font-weight: normal;
}