$nlfColorMain: #f00;
//$nav-height: 64px;
$nav-height: 64px;
$mobile-menu-width: 250px;
$nlf-blue-light: #0089BC;
$nlf-grey-dark: #404040;
$nlf-background: #fff;
$link-color: #ffab40;
$text-color: rgba(0,0,0,0.87);

$success: #27ae60;
$warning: #e67e22;
$danger: #c0392b;