.color-selector {
    .icon {
        border-radius: 100%;
        display: block;
        width: 20px;
        height: 20px;
    }

    .selected-icon {}
}
