.nlfcard {
  position: relative;
  font-size: 2.5vw;
  line-height: 1.2;

  &-image {
    & img {
      width: 100%;
      max-width: 100%;
    }
  }

  &-details {
    color: #fff;
    text-align: center;
    font-family: Helvetica, Arial, sans-serif;
    position: absolute;
    bottom: 15%;
    left: 0;
    width: 100%;

    &-customername {
      font-size: 1.3em;
    }
  }

  &-downloadlink {
    margin-bottom: 2em;
    margin-top: 1em;
  }
}
