.company-documents {
	max-width: 100vw;
	height: 100%;
	min-height: 100vh;
	padding: 10px;

	.icon {
		margin-right: 20px;
	}

}

.folder-view {
	background: #ececec;

	.card {
		max-height: 55px;
		margin: 10px;
		transition: max-height 0.5s;
		overflow: hidden;


		.details {
			
			padding-top: 20px;
			height: 158px;
			border-top: solid 1px #ededed;
			opacity: 0;
			visibility: hidden;

			transition: opacity 0.5s, visibility 0.5s;


			div {
				margin-bottom: 15px;
			}
		}


		&.open {
			max-height: 225px;


			.header {
				padding-bottom: 10px;
			}

			.details {
				opacity: 100;
				visibility: visible;

				button {
					margin-top: 20px;
				}
			}

		}


	}
}

