.icon-selector {
    .selected-icon {
        padding: 10px;
    }

    .icon {
        font-size: 35px;
        line-height: 1;
    }

    .icon-list {
        background: #fff;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        display: none;
        padding: 10px;
        list-style: none;
        margin: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        z-index: 9;
        overflow-x: auto;

        &.visible {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        li {
            &:first-child {
                margin-top: 0;
            }
        }

        .icon {
            cursor: pointer;

            &:hover {
                transform: scale(1.5);
            }
        }
    }
}