.contact-list {
    &__title {
        font-size: 28px;
        margin: 20px 0 10px 0;
        padding: 0 15px;
    }

    &__contacts {
        justify-content: center;

        .col {
            margin-left: auto;
            margin-right: auto;
        }

        .card-content {
            .card-title {
                background-color: white;
                background-color: transparent;
                font-size: 1.5em;
                line-height: 1;
            }
        }

        .contact-info {
            margin: 5px 0 0;

            li {
                font-size: 18px;

                .text {
                    margin-left: 10px;
                }

                .material-icons {
                    position: relative;
                    top: 6px;
                    font-size: 24px;
                    color: #34495E;
                }
            }
        }

        .card-action {
            a {
                color: #3498DB !important;
            }
        }
    }

    &__card {
        width: 32%;
        margin: 5px;

        &:first-child {
            margin-top: 0;
        }

        @media (max-width: 1200px) {
            width: 48%;
        }
    }

    &__message {
        p {
            font-size: 20px;
        }
    }
}

.company-info {
    padding: 0 15px 10px;

    &__entry {
        margin-top: 10px;

        .key {
            font-weight: bold;
        }

        .icon {
            vertical-align: middle;
        }
    }
}