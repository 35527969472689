.handbookMainWrapper {
	padding-right: 15px;
	padding-left: 15px;
}

.handbookMainWrapperMobile {
	padding-right: 15px;
	padding-left: 15px;

	.list {

		a {
			font-size: 18px;
			padding-bottom: 0.25em;
			padding-top: 0.25em;
			display: inline-block;
		}

		ul {
			padding-left: 1em;
		}
	}
}
