.deviation-data {
    td {
        display: block;
        margin-top: 10px;
        padding: 0;

        &:nth-child(2n+1) {
            margin-top: 20px;
        }
    }

    .action-buttons {
        margin-top: 30px;
    }
}

#avvikForm {
    input {
        &.invalid {
            border-bottom-color: #F44336;
        }
    }
}

