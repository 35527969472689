.timesheets-container {
  input[type=time]:disabled {
    color: rgba(0,0,0,0.56);
  }
}
.registrer-time {
  transition: all 0.2s ease-in-out;
  color: #fff;
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: calc(100vh - 64px);

  #title {
    text-transform: capitalize;
  }

  &.active {
    background: $success;

    .action-buttons span {
      background: darken($success, 5);
    }
  }

  .select-wrapper {
    color: #666;
  }

  #counter {
    transition: all 0.2s ease-in-out;
    height: 100px;
    opacity: 1;
    font-size: 2.5rem;

    &.disabled {
      height: 0;
      opacity: 0;
      overflow: hidden;
    }
  }

  h2 {
    text-align: center;
    font-size: 5rem;
    margin: 0;
  }

  .button {
    background: rgba(#000, 0.2);
    margin-bottom: 0;
  }

  .action-buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;

    p {
      color: $text-color;
    }

    .activity {
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all 0.2s ease-in-out;
      min-width: 45vw;
      flex-direction: column;
      margin-bottom: .6rem;
      padding-bottom: 2rem;
      border-radius: 10px;

      i {
        font-size: 3.5rem;
        margin-bottom: 0.5rem;
        margin-top: 1.5rem;
      }

      &.active {
        text-shadow: 0 0 10px lighten(#fff, 20);

        span {
          &:before {
            font-family: 'Material Icons';
            content: 'play_arrow';
            font-size: 1.5em;
            position: relative;
            right: 4px;
            top: 5px;
            line-height: 0;
          }
        }
      }
    }
  }
}

.application-buttons {
  display: flex;
  position: absolute;
  bottom: 50px;

  button {
    background-color: #34495e;
    box-shadow: none;
    border-right: 3px solid #EDEDED;
    font-size: .9em;
    color: #fff;
    display: flex;
    flex-direction: row;
    border-radius: 10px;
    min-height: 12vh;
    align-items: center;
    justify-content: center;

    &:first-child,
    &:nth-child(2) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:nth-child(3) {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      border-right: none;
    }

    i {
      font-size: 3em;
      margin-right: 5px;
    }
  }
}

.collapsible {
  .collapsible-body {
    padding: 5px;
    overflow-x: scroll;

    .id,
    .hash {
      display: none;
    }

    .delete,
    .actions {
      display: none;
    }

    .delete {
      text-shadow: 0 3px 5px rgba(0,0,0,0.16);
    }

    .total {
      text-align: center;
    }

    textarea {
      border: none;
      height: auto;
      padding: 10px;
    }

    .meta {
      padding-top: 10px;
      font-size: 12px;
      margin-bottom: 5px;
      position: relative;

      .meta-box {
        background: transparent;
        border: none;
        position: relative;
        padding-top: 30px;
        margin-top: -5px;
      }

      label {
        text-transform: capitalize;
        position: absolute;
        top: 10px;
        left: 10px;
        z-index: 99;
      }

      textarea {
        resize: none;
      }

      .comments {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }

      .category {
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
      }
    }
  }

  .remove {
    cursor: pointer;
    position: absolute;
    top: 9px;
    right: 10px;
  }

  .btn {
    margin: 5px;
  }

  select {
    display: inline-block;
  }

  li {
    position: relative;

    .edit-delete,
    .edit-date,
    .aside-save {
      display: none;
    }

    .add-row {
      display: none;

      i {
        vertical-align: text-bottom;
        position: relative;
        bottom: -4px;
        margin-right: 3px;
      }

      td {
        padding-top: 0;
        padding-bottom: 0;
      }
    }

  .description-box-trigger {
    color: #ccc;

    &.has-content {
      color: lighten(#1D8BF1, 20%);
    }
  }

  .description-box {
    display: none;
  }

  .open {
    position: absolute;
    left: 0;
    right: 0;
    z-index: 9;

    .description-box-trigger {
      display: none;
    }

    .description-box {
      position: relative;
      box-shadow: 0 2px 5px 0 rgba(0,0,0,0.16),0 2px 10px 0 rgba(0,0,0,0.12);
      display: block;
      background: #fff;
      padding: 20px;

      textarea {
        border-color: #ccc;
        padding: 10px;
        resize: vertical;
      }
    }

    .description-box-close {}
  }

  .activity {
    select {
      display: none;
    }
  }

  tr {
    position: relative;
  }
    &.is-editing {
      .add-row,
      .edit-delete,
      .edit-date,
      .aside-save
      {
        display: inline-block;
      }

      .meta {
        background: #fff;
      }

      .aside-send,
      .aside-edit {
        display: none;
      }

      .delete,
      .actions {
        display: table-cell;
      }

      .description-box-trigger {
        color: #000;
        cursor: pointer;

        &.has-content {
          color: #1D8BF1;
        }
      }

      .activity {
        select {
          display: block;
        }

        .text {
          display: none;
        }
      }
    }

    span {
      float: left;
    }

    .lock {
      display: none;
      position: absolute;
      right: 8px;
      margin: 0;
    }

    &.locked {
      .lock {
        display: inline-block;
      }

      .remove {
        display: none;
      }

      .aside-send,
      .aside-edit {
        display: none;
      }
    }
  }
}

#timesheet {
  #add-modal {
    right: auto;
  }

  #aside-new {
    float: right;
  }

  .start-options {
    color: #000;

    .modal-footer {
      display: flex;
      padding: 0;
    }

    .modal-action {
      margin-right: 3px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .modal {
    .modal-content {
      padding: 15px;
    }
  }
}

.lean-overlay {
  display: none !important;
}

.activity-settings {
    color: $text-color;

    &__block {
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    &__settings {
      display: flex;
    }

    &__icon-select {}

    .form-actions {
      margin-top: 20px;
    }

    .form-titles {
      border-bottom: 1px solid #eee;
      display: flex;
      padding-bottom: 15px;
      justify-content: space-between;

      .title {
        text-align: center;
        padding: 0 15px;
      }
    }
}

.new-timesheet-form {
  margin-bottom: 30px;
}