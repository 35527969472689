#editform {
    padding: 15px;

    input[type='checkbox'] {
        left: 0;
        position: relative;
        visibility: visible;
        float: left;
        margin-right: 5px;
    }

    textarea {
        border-color: #C2C2C2;
    }

    .form-item {
        margin-top: 5px;

        &:first-child {
            margin-top: 0;
        }
    }

    .datatype-image {
        strong {
            margin-top: 10px;
            display: block;
            font-size: 0.8rem;
            color: #9e9e9e;
        }
    }

    #id_other {
        margin-bottom: 20px;

        textarea {
            margin-top: 5px;
        }
    }

    // Hide certain fields for end user.
    #id_has_logged_in,
    #id_access {
        display: none;
    }
}
